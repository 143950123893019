<template>
  <div class="l-contents">
    <div class="l-box" v-if="list">
      <h3 class="c-lead icon">運搬終了報告（{{ `${index}/${total}` }}）</h3>
    </div>
    <div class="l-box" v-else>
      <h3 class="c-lead icon">運搬終了報告</h3>
    </div>
    <div v-if="type === collectReportDisplayType.AfterReport">
      <!-- 今回の対応、1区間目のみ、運搬終了報告方法を表示される -->
      <div v-if="index == 1" class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬終了報告方法</label>
        </div>
        <div v-if="list.collectReportedWayTypeId" class="c-inputWrap__items">
          <div v-if="list.collectReportedWayTypeId == collectReportedWayType.WELLFEST.value"><p>{{collectReportedWayType.WELLFEST.name}}</p></div>
          <div v-else><p>{{collectReportedWayType.JWNET.name}}</p></div>
        </div>
        <div v-else class="c-inputWrap__items"><p>-</p></div>
      </div>

      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬終了日</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.collectionReportDate ? list.collectionReportDate : "" | dateJpYMDFormat }}
        </div>
      </div>

      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">収集運搬業者名</label>
        </div>
        <div class="c-inputWrap__items">
          {{
            (list.collectCompany ? list.collectCompany.companyFullName : "")| blankText
          }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬担当者</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.collectMemberInfo ? list.collectMemberInfo.name : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">報告担当者</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.reportMemberInfo ? list.reportMemberInfo.name : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬方法</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.collectWay ? list.collectWay.name : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">車両番号</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.carNo ? list.carNo : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬量</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.collectQuantity ? list.collectQuantity : "" | formatFloat }}
          {{ list.collectWasteUnitType ? list.collectWasteUnitType.name : ""}}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">有価物収拾量</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.valuablesQuantity ? list.valuablesQuantity : "" | formatFloat }}
          {{ list.valuablesUnitType ? list.valuablesUnitType.name : ""}}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬先加入者名</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.collectFactoryInfo ? list.collectFactoryInfo.name : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬先事業場区分</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.destinationInfo ? list.destinationInfo.destinationTypeInfo ? list.destinationInfo.destinationTypeInfo.name : "" : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬先事業場名</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.destinationInfo ? list.destinationInfo.name : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">運搬先事業場住所</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.destinationInfo ? '〒' + list.destinationInfo.addressInfo.zipCode : "" | blankText }}<br />
          {{ list.destinationInfo ? list.destinationInfo.addressInfo.prefecturesName : "" }}
          {{ list.destinationInfo ? list.destinationInfo.addressInfo.address : "" }}
          {{ list.destinationInfo ? list.destinationInfo.addressInfo.buildingName : "" }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">備考</label>
        </div>
        <div class="c-inputWrap__items" style="overflow-wrap: anywhere">
          {{ list.remark ? list.remark : "" | blankText }}
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="wasteType && (wasteType.id === wasteTypeDomain.VALUABLES || wasteType.id === wasteTypeDomain.COMMON_WASTE)"><p>-</p></div>
      <div v-else><p>未報告</p></div>
    </div>
  </div>
</template>

<script>
import {DOMAIN, COLLECT_REPORTED_WAY_TYPE} from "@/const/index";
export default {
  props: {
    wasteType:{
      type: Object,
    },
    list: {
      type: Object,
    },
    index: {
      type: Number,
    },
    total: {
      type: Number,
    },
    type: {
      type: Number,
    }
  },
  data() {
    return {
      collectReportDisplayType: CollectReportDisplayType,
      wasteTypeDomain: DOMAIN.WASTE_TYPE,
      collectReportedWayType: COLLECT_REPORTED_WAY_TYPE,
    }
  }
};


const CollectReportDisplayType = {
  BeforeReport: 1,
  AfterReport: 2,
};

export { CollectReportDisplayType };
</script>
