import axios from "axios";
import { API_URL, LEGAL_PERSONALITY_TYPE } from "@/const";
import { saveAs } from "file-saver";
import manifestInfoTestData from "@/_json/_manifest.json";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  mixins: [ selectionLocalStorage ],
  data() {
    return {
      // API返り値（選択肢などに仕様）
      disposalWayTypesOpt: [],
      memberOpt: [],
      memberDisOpt: [],
      jwnetItemsOpt: [],
      recurryingTypesOpt: [],
      routesInfo: [],
      routeDetailInfo: {},
      wasteUnitOpt: [],
      confirmMemberOpt: [],
      shapeOpt: [],
      harmfulTypes: [],
      routeLabels: [],
      reportFixApprove: {
        manifestId: 123,
        manifestStatusId: 123,
        beforeCollectReportFixInfo: {
          section: 123,
          collectionReportDate: "",
          collectMemberName: "",
          reportMemberName: "",
          collectWayType: {
            id: null,
            name: "",
          },
          carNo: "",
          collectQuantity: null,
          collectWasteUnitType: {
            id: null,
            name: "",
          },
          valuablesQuantity: null,
          valuablesUnitType: {
            id: null,
            name: "",
          },
          remark: "",
        },
        afterCollectReportFixInfo: {
          section: 123,
          collectionReportDate: "",
          collectMemberName: "",
          reportMemberName: "",
          collectWayType: {
            id: null,
            name: "",
          },
          carNo: "",
          collectQuantity: null,
          collectWasteUnitType: {
            id: null,
            name: "",
          },
          valuablesQuantity: null,
          valuablesUnitType: {
            id: null,
            name: "",
          },
          remark: "",
        },
        beforeDisposalReportFixInfo: {
          disposalReportDate: "",
          wasteReceiptDate: "",
          acceptQuantity: null,
          acceptWasteInitType: {
            id: null,
            name: "",
          },
          collectMemberName: "",
          carNo: "",
          disposalMemberName: "",
          reportMemberName: "",
          remark: "",
        },
        afterDisposalReportFixInfo: {
          disposalReportDate: "",
          wasteReceiptDate: "",
          acceptQuantity: null,
          acceptWasteInitType: {
            id: null,
            name: "",
          },
          collectMemberName: "",
          carNo: "",
          disposalMemberName: "",
          reportMemberName: "",
          remark: "",
        },
      },
      wasteNameOpt: [],
      // ルート協議書アラート情報取得からの警告メッセージ
      routePriorConsultationAlertMsgs: "",
      routePriorConsultationIsAlert: false, // 事前協議書の警告基準値に到達したかどうか
      routePriorConsultationIsLimit: false, // 事前協議書の上限値を超過したかどうか
      prefectures: [],
      selectDisposalSiteList: [], //入力テキストで絞る排出事業者選択肢
      disposalSiteList: [],
      selectCollectCompanyList: [], //入力テキストで絞る運搬事業者選択肢
      collectCompanyList: [],
      selectDisposalCompanyList: [], //入力テキストで絞る処分事業者選択肢
      disposalCompanyList: [],
    };
  },
  methods: {
    //マニフェスト情報API    API_URL.MANIFEST.DETAIL + {id}
    getManifestInfoApi(manifestId) {
      /** データセット */
      const setData = (res) => {
        this.formValue = Object.assign({}, this.formValue, res);
        if (this.formValue.collectReportList) {
          for (let index in this.formValue.collectReportList) {
            var collectCompany = this.formValue.collectReportList[index].collectCompany;
            this.formValue.collectReportList[index].collectCompany.companyFullName = this.setCompanyFullName(collectCompany);
          }
        }
        if (this.formValue.disposalReportInfo) {
          var disposalCompany = this.formValue.disposalReportInfo.disposalCompany;
          this.formValue.disposalReportInfo.disposalCompany.companyFullName = this.setCompanyFullName(disposalCompany);
        }

        // 2022.07.04 自身で操作可能なマニフェストかどうか判定
        // 排出側は排出側で予約したマニフェスト、運搬側は運搬側で代理予約かつルートの第1区間目が自身のマニフェストが操作可能（予約or代理予約は代理登録担当者の有無で判定）
        const isProxyManifest = !!this.formValue.manifestInfo.proxyCollectMember;
        const firstCollectCompanyId = this.formValue.collectReportList ? this.formValue.collectReportList[0] ? this.formValue.collectReportList[0].collectCompany.id : null : null;
        this.isFirstCollectCompany = firstCollectCompanyId && firstCollectCompanyId === this.$store.getters["userInfo/get"].groupInfo.collectCompanyId;
        this.isOwnedManifest = (this.$store.getters["userInfo/isGenerator"] && !isProxyManifest)
          || (this.$store.getters["userInfo/isCollector"] && isProxyManifest && this.isFirstCollectCompany);
      }

      // テストデータ使用する場合
      if (process.env.VUE_APP_DATA_TYPE === "test") {
        console.log(manifestInfoTestData)
        // return new Promise(resolve => { resolve(setData(manifestInfoTestData)) })
      }

      return axios
        .get(`${API_URL.MANIFEST.DETAIL}${manifestId}`)
        .then((res) => {
          return setData(res.data)
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },

    //担当者一覧API    API_URL.MANIFEST.MEMBER_OPT
    getMemberOptApi(generateStoreId) {
      axios
        .get(API_URL.MANIFEST.MEMBER_OPT + generateStoreId)
        .then((res) => {
          this.memberOpt = res.data.MemberOptions;
          this.memberDisOpt = res.data.MemberOptions;

          // 削除されたアカウント、または自分が見えないアカウント（上位の階層）でも選択できるようにする
          if (this.formValue.manifestInfo.registMember && this.formValue.manifestInfo.registMember.id && !this.memberOpt.map(it => it.id).includes(this.formValue.manifestInfo.registMember.id)) {

            this.memberOpt.push({
              id: this.formValue.manifestInfo.registMember.id,
              name: this.formValue.manifestInfo.registMember.name
            });
          }
          if (this.formValue.manifestInfo.generateMember && this.formValue.manifestInfo.generateMember.id && !this.memberDisOpt.map(it => it.id).includes(this.formValue.manifestInfo.generateMember.id)) {
            this.memberDisOpt.push({
              id: this.formValue.manifestInfo.generateMember.id,
              name: this.formValue.manifestInfo.generateMember.name
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 担当者一覧API（排出）    API_URL.MANIFEST.MEMBER_OPT
    getDisMemberOptApi() {
      axios
        .get(API_URL.MANIFEST.MEMBER_OPT)
        .then((res) => {
          this.memberDisOpt = res.data.MemberOptions;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 委託契約に紐づく廃棄物種類選択肢取得API    API_URL.ENTRUST.JWNET_ITEM_TYPE
    getEntrustJwnetItemsApi(generateStoreId) {
      axios
        .get(
          API_URL.ENTRUST.JWNET_ITEM_TYPE +
            `?generateStoreId=${generateStoreId}`
        )
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート選択肢検索Urlの作成 isHasRouteListSearchModal: その画面でルート選択肢モーダルがあるかどうか
    getRouteListSearchApiUrl(generateStoreId, isHasRouteListSearchModal) {
      const params = new URLSearchParams();
      if (isHasRouteListSearchModal) {
        if (this.routeModalSearchParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.routeModalSearchParam.jwnetItemTypeId);
        if (this.routeModalSearchParam.routeName) params.append("routeName", this.routeModalSearchParam.routeName);
        if (this.routeModalSearchParam.wasteName) params.append("wasteName", this.routeModalSearchParam.wasteName);
        if (this.routeModalSearchParam.collectCompanyId) params.append("collectCompanyId", this.routeModalSearchParam.collectCompanyId);
        if (this.routeModalSearchParam.disposalCompanyId) params.append("disposalCompanyId", this.routeModalSearchParam.disposalCompanyId);
        if (this.routeModalSearchParam.disposalSiteId) params.append("disposalSiteId", this.routeModalSearchParam.disposalSiteId);

        if (this.routeModalSearchParam.wasteTypeIdList) params.append("wasteTypeIdList", this.routeModalSearchParam.wasteTypeIdList);
        if (this.routeModalSearchParam.industrialWasteJwnetTypeIdList) params.append("industrialWasteJwnetTypeIdList", this.routeModalSearchParam.industrialWasteJwnetTypeIdList);
        if (this.routeModalSearchParam.manifestTypeIdList) params.append("manifestTypeIdList", this.routeModalSearchParam.manifestTypeIdList);
        if (this.routeModalSearchParam.isFavorite) params.append("isFavorite", this.routeModalSearchParam.isFavorite);

      }
      return API_URL.ROUTE.LIST + generateStoreId + '?' + params.toString();
    },

    // ルート選択肢取API     API_URL.ROUTE.LIST  isHasRouteListSearchModal: その画面でルート選択肢モーダルがあるかどうか
    getRouteListApi(generateStoreId, isHasRouteListSearchModal) {
      axios
        .get(this.getRouteListSearchApiUrl(generateStoreId, isHasRouteListSearchModal))
        .then((res) => {
          this.routesInfo = res.data.routeList;
          this.narrowedRoutesList = [...this.routesInfo];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 数量確定者選択肢取得API    API_URL.MANIFEST.CONFIRM_MEMBER
    getConfirmMemberApi(routeId) {
      return axios
        .get(API_URL.MANIFEST.CONFIRM_MEMBER + routeId)
        .then((res) => {
          this.confirmMemberOpt = res.data.quantityConfirmMembers;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getHarmfulTypesApi() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 荷姿選択肢取得API    API_URL.DOMAIN.SHAPE
    getShapeApi() {
      return axios
        .get(API_URL.DOMAIN.SHAPE)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルートラベル取得API    API_URL.ROUTE.LABEL_DETAIL/{id}
    getRouteLabelsApi() {
      console.log(this.formValue.routeInfo.routeId);
      const routeId = this.formValue.routeInfo.routeId;
      return axios
        .get(`${API_URL.ROUTE.LABEL_DETAIL}${routeId}`)
        .then((res) => {
          this.routeLabels = res.data.routeLabels;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 終了報告修正承認情報取得API
    getManifestReportfixApproveApi(manifestId, isFixing) {
      axios
        .get(API_URL.MANIFEST.REPORT_FIX_APPROVE + manifestId)
        //.get("/v_resources/data/json/reportFixApprove.json")
        .then((res) => {
          this.reportFixApprove = res.data;
        })
        .then(() => {
          this.isApproveConfirmModalShow = true;
          this.isFixingApproveConfirmFlag = isFixing;
        })
        .catch((err) => {
          this.errMsgs.push(err);
        });
    },

    // ルート情報API API_URL.ROUTE.DETAIL/{routeId}
    getRouteInfoApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          this.routeDetailInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA品目別廃棄物名称選択肢取得API    API_URL.DOMAIN.WASTE_NAME_CBA_ITEM
    getWasteNameCBAItemApi(cbaItemId) {
      axios
        .get(API_URL.DOMAIN.WASTE_NAME_CBA_ITEM(cbaItemId))
        .then((res) => {
          this.wasteNameOpt = res.data.wasteNameList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getWDSDownloadApi(routeId, wdsFileName) {
      return axios
        .get(`${API_URL.MANIFEST.WDS_DOWNLOAD}${routeId}`, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分方法選択肢取得API    API_URL.DOMAIN.DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi() {
      return axios
        .get(API_URL.DOMAIN.DISPOSAL_WAY_TYPE)
        .then((res) => {
          this.disposalWayTypesOpt = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    postDeliveryChecksheetApi(manifestId) {
      return axios
        .post(
          API_URL.MANIFEST.DELIVERY_CHECKSHEET,
          {
            manifestIdList: [{ manifestId: manifestId }],
          },
          {
            responseType: "blob",
          }
        )
        .catch((err) => {
          console.log(err);
        });
    },

    getRouteShapeTypeApi(routeId) {
      return axios
        .get(`${API_URL.ROUTE.ROUTE_SHAPE_TYPE}${routeId}`)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRouteDisposalWayTypeApi(routeId) {
      return axios
        .get(`${API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE}${routeId}`)
        .then((res) => {
          this.disposalWayTypesOpt = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getManifestEditRequestApi(manifestId) {
      return axios
        .get(`${API_URL.MANIFEST.EDIT_REQUEST_ID}/${manifestId}`)
        .then((res) => {
          this.manifestEditRequest = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putManifestEditApproveManifestIdApprove(manifestId) {
      return this.putManifestEditApproveManifestId(manifestId, true, null);
    },
    putManifestEditApproveManifestIdDenial(manifestId, denyReason) {
      return this.putManifestEditApproveManifestId(
        manifestId,
        false,
        denyReason
      );
    },
    putManifestEditApproveManifestId(manifestId, isApprove, denyReason) {
      return axios
        .put(`${API_URL.MANIFEST.EDIT_APPROVE_ID}/${manifestId}`, {
          isApprove: isApprove,
          denialReason: denyReason,
        })
        .then((res) => {
          return res.data.manifestId;
        });
    },
    setCompanyFullName(collectCompany) {
      var legalPersonalityTypeName = collectCompany.legalPersonalityTypeName;
      var companyName = collectCompany.name;
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(legalPersonalityTypeName);
      if (matches) {
        var location = matches[1];
        if (location === LEGAL_PERSONALITY_TYPE.LOCATION.BEFORE) {
          return legalPersonalityTypeName.replace(matches[0], "") + companyName ;
        } else if (location === LEGAL_PERSONALITY_TYPE.LOCATION.AFTER) {
          return companyName + legalPersonalityTypeName.replace(matches[0], "");
        }
        return companyName;
      } else return companyName;
    },


    // ルートに紐づく事前協議書のアラート情報取得API
    getRoutePriorconsultationAlert() {
      this.routePriorConsultationAlertMsgs = "";
      this.routePriorConsultationIsLimit = false;
      this.routePriorConsultationIsAlert = false;
      const params = new URLSearchParams();
      if (this.formValue.manifestInfo.routeId) { params.append('routeId', this.formValue.manifestInfo.routeId) } else return
      if (this.formValue.manifestInfo.collectionDate) params.append('collectionDate', this.formValue.manifestInfo.collectionDate);
      return axios
        .get(`${API_URL.ROUTE.ROUTE_PRIORCONSULTATION_ALERT}` + '?' + params.toString())
        .then((res) => {
          if (res.data.isLimit) {
            this.routePriorConsultationIsLimit = true;
            this.routePriorConsultationAlertMsgs = '事前協議書の上限値を超過しています。'
          } else if (res.data.isAlert) {
            this.routePriorConsultationIsAlert = true;
            this.routePriorConsultationAlertMsgs = '事前協議書の警告基準値に到達しました。'
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分場選択肢取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data;
        this.selectDisposalSiteList = data;
      }).catch(() => {})
    },

    // 排出用、運搬事業者選択肢取得API
    getCollectCompanyForGenerate() {
      this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
        this.collectCompanyList = data;
        this.selectCollectCompanyList = data;
      }).catch(() => {})
    },

    // 処分事業者選択肢取得API
    getDisposalCompany() {
      this.getGeneratorDisposalSelection().then((data) => {
        this.selectDisposalCompanyList = data;
        this.disposalCompanyList = data;
      }).catch(() => {})
    },
  },
};
